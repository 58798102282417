<template>
  <div class="app-main-content">
    <div class="header">
      <div class="title">
        <h2>第三代人工智能制造技术</h2>
      </div>
    </div>
    <div class="content">
      <a-table :data-source="data" rowKey="oid" :expandIcon="this.$customExpandIcon" :indentSize="25"
               :columns="columns" :pagination="false" style="width: 1437px">
        <!--        <a-table-column title="标题" data-index="name"  width="433px"/>-->
        <!--        <a-table-column title="类型" data-index="a" width="130px" align="center"/>-->
        <!--        <a-table-column title="进度" data-index="b" width="130px" align="center"/>-->
        <!--        <a-table-column title="负责人" data-index="c" width="130px" align="center"/>-->
        <template slot="phase_name" slot-scope="scope">
          <span class="edp-title">
            <img :src="`${$appsettings.imgPrefix}folder.png`" alt="">
            <span class="name">{{ scope }}</span>
            <i class="iconfont icon-wenbenshuru"></i>
            <i class="iconfont icon-guanlian"></i>
            <i class="iconfont icon-a-xinjiantianjia"></i>
            <i class="iconfont icon-V"></i>
            <i class="iconfont icon-liebiao"></i>
            <i class="iconfont icon-shanchuwenjianjia"></i>
          </span>
        </template>
        <template slot="owners" slot-scope="scope">
          <div class="edp-owners">
            <div v-for="item in scope" :key="item.oid">
              <a-avatar :size="16" :src="item.pic" />
              <span>{{ item.name }}</span>
            </div>
          </div>
        </template>
        <template slot="issue_level_name" slot-scope="scope">
          <span class="edp-tag red" v-if="scope">{{ scope }}</span>
        </template>
        <!--        <a-table-column title="开始日期" data-index="e" width="130px" align="center"/>-->
        <!--        <a-table-column title="截止日期" data-index="f" width="130px"  align="center"/>-->
      </a-table>
    </div>
  </div>
</template>

<script>
import appsettings from "@/utils/appsettings";
const data = [
  {
    key: 1,
    phase_name: '某某项目',
    issue_type_name: '阶段',
    complete_percent: '30%',
    owners: '小陈',
    schedule_count: '91',
    actual_count: '43',
    children: [
      {
        key: 11,
        phase_name: 'John Brown',
        issue_type_name: '任务',
        complete_percent: '80%',
        owners: '小东',
        issue_level_name: '最高',
        schedule_count: '1',
        actual_count: '0',
        deadline: '2023-01-15'
      }
    ]
  },
  {
    key: 2,
    phase_name: '某某项目',
    issue_type_name: '阶段',
    complete_percent: '30%',
    owners: '小陈',
    schedule_count: '50',
    actual_count: '29'
  },
];
const columns = [
  {
    title: '标题',
    dataIndex: 'phase_name',
    scopedSlots: { customRender: 'phase_name'},
  },
  {
    title: '类型',
    dataIndex: 'issue_type_name',
    width: '130px'
  },
  {
    title: '进度',
    dataIndex: 'complete_percent',
    width: '130px'
  },
  {
    title: '负责人',
    dataIndex: 'owners',
    width: '130px',
    scopedSlots: { customRender: 'owners'},
  },
  {
    title: '计划数量',
    dataIndex: 'schedule_count',
    width: '130px'
  },
  {
    title: '实际数量',
    dataIndex: 'actual_count',
    width: '130px'
  }
];
export default {
  name: "projectPlanDeliverableDetail",
  data() {
    return{
      data,
      columns,
      appsettings
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">
.app-main-content{
  padding: 0 7px;
  //height: calc(100% - 50px);
  box-shadow: 0 2px 10px 0 rgba(182,182,182,0.50);

  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 46px 12px 20px;
    background: #FFFFFF;
    border-radius: 4px 4px 0 0;

    .title{
      font-size: 12px;
      color: #494949;

      h2{
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        color: #494949;
      }
    }
  }

  .content{
    height: calc(100% - 87px);
    background: #FFFFFF;
  }
}
</style>
